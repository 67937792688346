:root {
  --button-color: #0063b5;
}

.user-info {
  padding: 20px;
  text-align: center;
  border-right: 1px solid #ccc;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-details {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
}

.user-photo {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.user-name {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
}

.user-details {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
}

.user-photo {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
}

.user-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.user-contact-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 10px;
}

.user-contact {
  font-size: 16px;
  margin: 2px 0;
  display: flex;
  align-items: center;
  text-align: left;
  /* color: var(--button-color); */
  width: 100%;
}

.user-contact svg {
  margin-right: 10px;
}

.company-logo {
  width: 40%;
  margin-top: 1px;
}

hr {
  width: 80%;
  margin: 5px 0;
  border: 0;
  border-top: 1px solid #ccc;
}

.calendly-button {
  background-color: var(--button-color);
  border: none;
  color: white;
  padding: 10px;
  width: 70%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 20px auto;
  cursor: pointer;
  border-radius: 12px;
}

.social-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  background-color: #FFFFFF;
}

a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-2px);
}

a.youtube {
  color: #eb3223;
}

a.facebook {
  color: #4968ad;
}

a.twitter {
  color: #49a1eb;
}

a.instagram {
  color: black;
}

a.linkedin {
  color: #0077b5;
}

a.github {
  color: #333;
}

a.website {
  color: #333;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  body, html {
    height: auto;
    overflow: auto;
  }

  .container {
    flex-direction: column;
    height: 100%;
    width: 100%;
    box-shadow: none;
  }

  .user-info, .chat-interface {
    flex: none;
    width: 100%;
    height: auto;
    border-right: none;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  .calendly-button {
    width: 90%;
    font-size: 14px;
    padding: 10px;
  }

  .logos-container {
    flex-direction: column;
    gap: 5px;
  }

  .language-selector {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    width: 100%;
    justify-content: center;
  }

  .language-selector button {
    flex: 1;
    max-width: 100px;
    padding: 8px;
  }

  .user-info {
    padding: 10px;
    gap: 10px;
  }

  .user-details {
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
  }

  .user-photo {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
    margin-right: 0;
  }

  .user-name {
    font-size: 16px;
    text-align: center;
  }

  .company-logo {
    width: 60%;
  }

  .social-container {
    flex-wrap: wrap;
    margin-top: 15px;
    justify-content: center; /* Ensure items are centered */
  }

  a.social {
    margin: 5px;
    flex: 1 1 22%; /* Adjust the width and ensure wrapping */
    max-width: 50px; /* Optional: set a max-width if needed */
  }
}