.chat-interface {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chat-top {
  display: flex;
  justify-content: flex-end;
  background-color: #0063b5;
}

.chat-container {
  flex: 1;
  overflow: hidden;
}

deep-chat {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure Deep Chat takes full height */
}

deep-chat::part(base) {
  flex: 1;
  display: flex;
  flex-direction: column;
}

deep-chat::part(messages) {
  flex: 1;
  overflow-y: auto;
}

deep-chat::part(input-container) {
  margin-top: auto;
}

.intro-panel {
  width: 80%;
  max-width: 400px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  text-align: center;
  margin: 20px auto;
}

.intro-logo {
  width: 64px;
  height: 64px;
  margin-bottom: 10px;
}

.intro-panel h2 {
  margin: 10px 0;
  font-size: 24px;
  color: #333;
}

.intro-panel p {
  margin: 10px 0;
  color: #666;
}

.timestamp {
  color: #888;
  font-size: 12px;
  display: block;
  margin-bottom: 15px;
}

.intro-consent {
  background-color: #e9ecef;
  padding: 15px;
  font-size: 14px;
  border-radius: 8px;
  margin-top: 15px;
  text-align: left;
}

.intro-consent a {
  color: #0063b5;
  text-decoration: none;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .intro-panel {
    width: 90%;
    padding: 15px;
  }

  .intro-panel h2 {
    font-size: 20px;
    margin: 8px 0;
  }

  .intro-panel p {
    font-size: 14px;
    margin: 8px 0;
  }

  .intro-logo {
    width: 48px;
    height: 48px;
    margin-bottom: 8px;
  }

  .intro-consent {
    padding: 10px;
    font-size: 12px;
  }
}