:root {
  --background-color: #012f4e;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Open Sans', Arial, sans-serif;
  background: var(--background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.container {
  width: 90%;
  max-width: 1000px;
  height: 90%;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  border-radius: 10px;
}

.app-container {
  width: 100%;
  height: 100vh;
  background: var(--background-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-container {
  width: 90%;
  max-width: 1080px;
  height: 90vh;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  border-radius: 10px;
  overflow: hidden;
}

.content-container > div:nth-child(2) {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.chat-interface-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .content-container {
    flex-direction: column;
    height: 100%;
    width: 100%;
    box-shadow: none;
    overflow: auto;
  }

  .container {
    flex-direction: column;
    height: 100%;
    width: 100%;
    box-shadow: none;
    overflow: auto;
  }
}
